<template>
	<div class="detail">
		<Header :title="isEdit ? '修改退改签政策' : '新增退改签政策'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="formRef" :model="modelRef" scrollToFirstError name="formRef"
				:labelCol="{span: 6, xxl: 5 }" :wrapperCol="{span: 14, xxl: 13 }" @finish="onSubmit">
				<a-form-item name="cinemaId" label="所属影院" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-select placeholder="请选择所属影院" style="width: 300px;" v-model:value="modelRef.cinemaId">
						<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id">
							{{ item.name }}</a-select-option>
					</a-select>
				</a-form-item>

				<a-form-item class="ui-form__item" name="refundName" label="规则名称"
					:rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-input style="width: 200px;" v-model:value="modelRef.refundName" placeholder="请输入规则名称"></a-input>
				</a-form-item>

				<a-form-item label="是否可退票" name="refundFlag" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.refundFlag">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<div v-if="modelRef.refundFlag">
					<a-form-item class="ui-form__item" name="refundDayCount" label="每日可退次数"
						:rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-input-number style="width: 200px;" :min="0" :precision="0"
							v-model:value="modelRef.refundDayCount" placeholder="请输入每日可退次数"></a-input-number>
						<span style="padding-left: 6px;">
							次
						</span>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="refundBeforeShowTime" label="场次可退时间"
						:rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-input-number style="width: 200px;" :precision="0" v-model:value="modelRef.refundBeforeShowTime"
							placeholder="请输入场次可退时间"></a-input-number>
						<span style="padding-left: 6px;">
							分钟
							<a-tooltip style="margin-left: 6px;">
								<template #title>开场前多少分钟后不可退（正数：开场前，负数：开场后）</template>
								<Icon icon="QuestionCircleOutlined"></Icon>
							</a-tooltip>
						</span>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="refundServiceFee" label="退票服务费"
						:rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-input-number style="width: 200px;" :min="0" :precision="2"
							v-model:value="modelRef.refundServiceFee" placeholder="请输入退票服务费"></a-input-number>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="refundServiceType" label="退票服务费类型">
						<a-radio-group v-model:value="modelRef.refundServiceType">
							<a-radio :value="1">以票为单位</a-radio>
							<a-radio :value="2">以订单为单位</a-radio>
						</a-radio-group>
					</a-form-item>
				</div>

				<a-form-item label="是否可改签" name="changeFlag" :rules="[{ required: true, message: '必选项不允许为空' }]">
					<a-radio-group v-model:value="modelRef.changeFlag">
						<a-radio :value="1">是</a-radio>
						<a-radio :value="0">否</a-radio>
					</a-radio-group>
				</a-form-item>
				
				<div v-if="modelRef.changeFlag">
					<a-form-item class="ui-form__item" name="changeDayCount" label="每日可改签次数"
						:rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-input-number style="width: 200px;" :min="0" :precision="0"
							v-model:value="modelRef.changeDayCount" placeholder="请输入每日可退次数"></a-input-number>
						<span style="padding-left: 6px;">
							次
						</span>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="changeBeforeShowTime" label="场次可改签时间"
						:rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-input-number style="width: 200px;" :precision="0" v-model:value="modelRef.changeBeforeShowTime"
							placeholder="请输入场次可退时间"></a-input-number>
						<span style="padding-left: 6px;">
							分钟
							<a-tooltip style="margin-left: 6px;">
								<template #title>开场前多少分钟后不可改签（正数：开场前，负数：开场后）</template>
								<Icon icon="QuestionCircleOutlined"></Icon>
							</a-tooltip>
						</span>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="changeServiceFee" label="改签服务费"
						:rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-input-number style="width: 200px;" :min="0" :precision="2"
							v-model:value="modelRef.changeServiceFee" placeholder="请输入退票服务费"></a-input-number>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="changeServiceType" label="改签服务费类型">
						<a-radio-group v-model:value="modelRef.changeServiceType">
							<a-radio :value="1">以票为单位</a-radio>
							<a-radio :value="2">以订单为单位</a-radio>
						</a-radio-group>
					</a-form-item>
				</div>

				<a-form-item class="ui-form__item" name="refundLevel" label="规则优先级"
					extra="默认仅使用优先级最高的退改签政策，优先级越高越靠前，不填则为0">
					<a-input-number style="width: 200px;" :min="0" :precision="0" v-model:value="modelRef.refundLevel"
						placeholder="请输入规则优先级"></a-input-number>
				</a-form-item>

				<a-form-item label="是否启用" name="isDisabled">
					<a-switch v-model:checked="modelRef.isDisabled" checked-children="启用" un-checked-children="禁用" />
				</a-form-item>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button type="primary" html-type="submit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import {
		saveRefundPolicy,
		getCinemaList,
		getRefundPolicyDetail,
		updateRefundPolicy
	} from '@/service/modules/cinema.js'
	import Header from '@/components/header/header.vue';
	export default {
		name: "evaluateDetail",
		components: {
			Icon,
			Header
		},
		props: {
			isEdit: {
				type: Boolean,
				default: false
			},
			id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				cinemaAllList: [],
				loading: false,
				modelRef: {
					refundFlag: 0,
					changeFlag: 0,
					isDisabled: true,
					refundServiceType: 1,
					changeServiceType: 1,
				},
			};
		},
		created() {
			this.getAllCinemaList();
			if (this.isEdit && this.id) {
				this.getData();
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			async onSubmit() {
				this.$confirm({
					title: '提示',
					content: '确认要提交并保存吗？',
					onOk: async () => {
						let postData = JSON.parse(JSON.stringify(this.modelRef));
						postData.isDisabled = postData.isDisabled ? 0 : 1;
						this.loading = true;
						try {
							let ret;
							if (this.isEdit) {
								postData.id = this.id;
								ret = await updateRefundPolicy(postData);
							} else {
								ret = await saveRefundPolicy(postData);
							}
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.onBack(true);
							}
						} catch (e) {
							this.loading = false;
						}
					}
				})
			},
			async getData() {
				this.loading = true;
				try {
					const ret = await getRefundPolicyDetail({
						id: this.id
					})
					this.loading = false
					if (ret.code === 200) {
						ret.data.isDisabled = ret.data.isDisabled ? false : true;
						this.modelRef = ret.data;
					}
				} catch (error) {
					this.loading = false;
				}
			},
			async getAllCinemaList() {
				this.cinemaAllList = [];
				this.modelRef.cinemaId = undefined;
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
				})
				if (ret.code === 200) {
					this.cinemaAllList = ret.data.list;
				}
			},
		}
	};
</script>

<style lang="less" scoped>

</style>